@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

@layer base {
  :root {
    --background: 0 0% 100%; /* white */
    --foreground: 0 0% 0%; /* black */

    --card: 0 0% 100%; /* white */
    --card-foreground: 0 0% 0%; /* black */

    --popover: 0 0% 100%; /* white */
    --popover-foreground: 0 0% 0%; /* black */

    --primary: 261 81% 52%; /* violet */
    --primary-foreground: 0 0% 100%; /* white */

    --secondary: 261 50% 90%; /* light violet */
    --secondary-foreground: 0 0% 0%; /* black */

    --tertiary: 0 0% 98%; /* very light gray */
    --tertiary-foreground: 0 0% 50%; /* dark gray for contrast */

    --muted: 0 0% 95%; /* light gray */
    --muted-foreground: 215 14% 34%; /* gray */

    --accent: 261 50% 90%; /* light violet */
    --accent-foreground: 0 0% 0%; /* black */

    --destructive: 0 84% 60%; /* red */
    --destructive-foreground: 0 0% 100%; /* white */

    --border: 0 0% 85%; /* light gray */
    --input: 0 0% 90%; /* light gray */
    --ring: 261 81% 52%; /* violet */
    --radius: 0.5rem; /* border radius */
  }

  .dark {
    --background: 0 0% 0%; /* black */
    --foreground: 0 0% 100%; /* white */

    --card: 0 0% 10%; /* dark gray */
    --card-foreground: 0 0% 100%; /* white */

    --popover: 0 0% 10%; /* dark gray */
    --popover-foreground: 0 0% 100%; /* white */

    --primary: 255 90% 65%; /* violet */
    --primary-foreground: 0 0% 100%; /* white */

    --secondary: 261 50% 50%; /* medium violet */
    --secondary-foreground: 0 0% 100%; /* white */

    --tertiary: 0 0% 10%; /* dark gray */
    --tertiary-foreground: 0 0% 40%; /* light gray for contrast */

    --secondary: 261 50% 50%; /* medium violet */
    --secondary-foreground: 0 0% 100%; /* white */

    --muted: 0 0% 10%; /* dark gray */
    --muted-foreground: 0 0% 70%; /* light gray */

    --accent: 261 50% 50%; /* medium violet */
    --accent-foreground: 0 0% 100%; /* white */

    --destructive: 0 72% 50%; /* dark red */
    --destructive-foreground: 0 0% 100%; /* white */

    --border: 0 0% 20%; /* dark gray */
    --input: 0 0% 20%; /* dark gray */
    --ring: 255 90% 65%; /* violet */
  }
}

.shadow-light {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.085);
}

.shadow-dark {
  box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.141);
}

.anchor-link {
  position: relative;
  margin-left: -1.5rem;
  cursor: pointer;
  padding-right: 0.5rem;
}

.anchor-link::after {
  content: "#";
  color: rgb(156, 163, 175);
  margin-left: 0.25rem;
}

@layer base {
  html {
    @apply bg-background;
  }

  * {
    @apply border-border;
  }

  body {
    @apply text-foreground;

    --dot-bg: hsl(var(--background));
    --dot-color: hsl(var(--tertiary-foreground));
    --dot-size: 0.75px;
    --dot-space: 20px;
    background: linear-gradient(
          90deg,
          var(--dot-bg) calc(var(--dot-space) - var(--dot-size)),
          transparent 1%
        )
        center / var(--dot-space) var(--dot-space),
      linear-gradient(
          var(--dot-bg) calc(var(--dot-space) - var(--dot-size)),
          transparent 1%
        )
        center / var(--dot-space) var(--dot-space),
      var(--dot-color);
  }
}
